import { State, Action, StateContext } from '@ngxs/store';
import { User } from './models/user';
import { FetchUserDetails, FetchUserDetailsSuccess, FetchUserDetailsFailed } from './actions/app.actions';
import { AuthService } from './core/services/auth.service';

export interface AppStateModel {
  userDetails: User;
  loading: boolean;
  error: any;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    userDetails: null,
    loading: false,
    error: null
  }
})
export class AppState {
  constructor(
    private authService: AuthService
  ) {}

  @Action(FetchUserDetails)
  fetch({ patchState, dispatch }: StateContext<AppStateModel>) {
    patchState({ loading: true });

    return this.authService.getUserDetails().subscribe(
      (userDetails: User) => {
        return dispatch(new FetchUserDetailsSuccess(userDetails));
      },
      error => {
        return dispatch(new FetchUserDetailsFailed(error));
      }
    );
  }

  @Action(FetchUserDetailsSuccess)
  success(
    { patchState }: StateContext<AppStateModel>,
    { userDetails }: FetchUserDetailsSuccess
  ) {
    patchState({
      loading: false,
      error: null,
      userDetails
    });
  }

  @Action(FetchUserDetailsFailed)
  error(
    { patchState }: StateContext<AppStateModel>,
    { error }: FetchUserDetailsFailed
  ) {
    patchState({
      loading: false,
      userDetails: null,
      error
    });
  }
}


