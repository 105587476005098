<footer>
  <section class="main">

    <div class="info-links-wrap grow-child">
      <p class="heading">Help and Info</p>
      <ul class="page-links">
        <li *ngFor="let link of footerProps.infoLinks">
          <a rel="noopener" class="info-link" target="_blank" href="{{link.url}}">{{link.name}}</a>
        </li>
      </ul>
    </div>

    <div class="logo-wrap">
      <a rel="noopener" class="logo-link" [href]="footerProps.logo.href" target="_blank">
        <img class="logo-image" [src]="footerProps.logo.url" [alt]="footerProps.logo.alt" height="100%" width="100%" />
      </a>
    </div>

    <div class="social-links-wrap grow-child social-container">
      <p class="heading">Connect with us</p>
      <ul class="social-link-list">
        <li class="social-link-list-item" *ngFor="let link of footerProps.socialLinks">
          <a rel="noopener" class="social-link" target="_blank" href="{{link.url}}" title="{{link.name}}">
            <i class="fa {{link.icon}} fa-2x"></i>
          </a>
        </li>
      </ul>
    </div>

  </section>

  <section class="legal legal-wrap">
    <ul class="legal-link-list">
      <li *ngFor="let link of footerProps.legalLinks">
        <a rel="noopener" class="legal-link" target="_blank" href="{{link.url}}">{{link.name}}</a>
      </li>
    </ul>
  </section>
</footer>