import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'dashboard/auth/callback',
    redirectTo: 'auth/callback'
  },
  {
    path: 'auth/callback',
    loadChildren: './core/auth-callback/auth-callback.module#CallbackModule'
  },
  {
    path: '',
    loadChildren: './core/dashboard/dashboard.module#DashboardModule',
  },
  {
    path: '404',
    loadChildren: './core/404/page-not-found.module#PageNotFoundModule',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
