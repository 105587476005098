import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { User } from 'src/app/models/user';
import { HeaderProps } from 'src/app/props/header.props';
import { Store } from '@ngxs/store';
import { UpdateSubmissionsState } from '../../actions/submissions.actions';
import { SubmissionsStateModel } from '../submissions/submissions.state';
import { Submission } from '../../models/submission';
import * as SUBMISSION_CONFIG from '../../../config/submission.config';
import { SubmissionViewManagementService } from '../services/submission-view-management.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  protected showActions = false;
  showNotifications = false;
  submissionsState: SubmissionsStateModel;
  notifiableSubmissions: Submission[];
  activeSubmissions: Submission[];
  scrollInProgress: boolean;
  notificationCount: number;
  isNotificationClicked: {[key: string]: boolean} = {};
  isInit: boolean = true; 
  
  @Input()
  headerProps: HeaderProps;
  @Input()
  userDetails: User;
  @Output()
  logout = new EventEmitter();
  @ViewChild('actions', { read: ElementRef })
  actions: ElementRef;
  @ViewChild('notifications', { read: ElementRef })
  notifications: ElementRef;

  constructor(
    private store: Store,
    private renderer: Renderer2,
    private submissionViewManagement: SubmissionViewManagementService
    ) {
    this.renderer.listen('window', 'click', (e:Event)=>{
      if(this.actions && !(this.actions.nativeElement).contains(e.target)){
        this.showActions=false;
      }
      if(this.notifications && !(this.notifications.nativeElement).contains(e.target)){
        this.showNotifications=false;
      }
    });
  }

  ngOnInit() { 
    this.store
      .select(state => state.submissions)
      .subscribe((submissionsState: SubmissionsStateModel) => {
        this.submissionsState = submissionsState;
        if(this.submissionsState.submissions && this.submissionsState.submissions.length > 0) {
          this.activeSubmissions = this.submissionsState.submissions
                                    .filter(submission => submission.status !== "DELETED")
          this.notifiableSubmissions = this.activeSubmissions
                                        .filter(submission => this.isActionRequired(submission));
          
          if(this.isInit){
            this.isInit = false;
            this.notificationCount = this.notifiableSubmissions.length;
            this.notifiableSubmissions.forEach(submission => {
              this.isNotificationClicked[submission.submissionId] = false;
            });
          }
        }
      });
  }

  get state() {
    return this.submissionsState;
  }

  handleLogout() {
    this.logout.emit();
  }

  showActionsMenu() {
    this.showActions = !this.showActions;
  }

  showNotificationsMenu() {
    if(this.notifiableSubmissions.length > 0) {
      this.showNotifications = !this.showNotifications;
    }
  }

  isActionRequired(submission: Submission): boolean {
    if (SUBMISSION_CONFIG.actionStatuses.includes(submission.status)) {
      return true;
    }
  }

  scrollToSubmission(submissionId: string) {
    const indexOfSelectedSubFromAllSubs = this.activeSubmissions.findIndex((element) => element.submissionId === submissionId);

    this.isNotificationClicked[submissionId] = true;
    this.notificationCount = Object.keys(this.isNotificationClicked).filter(key => !this.isNotificationClicked[key]).length;
    
    this.submissionViewManagement.scrollToSelectedSubmissionCard(indexOfSelectedSubFromAllSubs);
    this.expandSelectedSubmissionCard(indexOfSelectedSubFromAllSubs);
  }

  expandSelectedSubmissionCard(indexOfSelectedSubFromAllSubs) {
    setTimeout(() => {
      const selectedIndex = this.activeSubmissions.findIndex((element) => element.isExpanded);
      if (selectedIndex !== -1) {
        this.activeSubmissions[selectedIndex].isExpanded = false;
      }
      this.activeSubmissions[indexOfSelectedSubFromAllSubs].isExpanded = true;
  
      this.showNotifications = false;
      
      this.store.dispatch(new UpdateSubmissionsState(this.activeSubmissions));
    },100);
  }

  extractStatusFromMap(status: string): string{
    return SUBMISSION_CONFIG.statusMap[status];
  }
  
  getDisplayName(details: User) {
    let displayName = '';
    if (details) {
      if (details.firstName) {
        displayName = details.firstName;
      } else if (details.displayName) {
        displayName = details.displayName;
      } else if (details.lastName) {
        displayName = details.lastName;
      } else if (details.email) {
        displayName = details.email;
      }
    }
    return displayName;
  }

   onError(event, fallbackImgPath) {
      event.target.src = fallbackImgPath;
    }
}
