import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import CONSTANTS from '../../../config/constants';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/auth.service';
import { SentryErrorHandler } from '../services/sentry.error.handler';
import { WindowRefService } from '../services/window.ref.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private sentryErrorHandler: SentryErrorHandler,
    private windowRef: WindowRefService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authorizationToken =
      'idtoken ' +
      this.cookieService.get(CONSTANTS.USER_MANAGEMENT_ID_TOKEN_COOKIE);
    const accessToken = this.cookieService.get(
      CONSTANTS.USER_MANAGEMENT_ACCESS_TOKEN_COOKIE
    );
    const clone = request.clone({
      setHeaders: this.getHeaders(request.url, accessToken, authorizationToken)
    });
    return next
      .handle(clone)
      .pipe(catchError(err => this.handleHttpError(err)));
  }

  private getHeaders(requestUrl: string, accessToken: string, authorizationToken: string): { [name: string]: string | string[]; } {
    let headers = {
        'USR-MGMT-ACCESS-TOKEN': accessToken,
        Authorization: authorizationToken,
        'Content-Type': 'application/json'
    }
    if(!requestUrl.includes('secure')){
      headers['Cache-Control'] = 'no-cache' ;
      headers['Pragma'] = 'no-cache' ;
    }
    return headers;
  }

  private handleHttpError(error) {
    const errorMessage = error.message || 'Unlabled Error';
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401 || error.status === 403) {
        this.handleErrorStatus();
      } else {
        this.sentryErrorHandler.sendError(errorMessage, error);
        return throwError(error);
      }
    } else {
      this.sentryErrorHandler.sendError(errorMessage, error);
      return throwError(error);
    }
  }
  private handleErrorStatus() {
    const dateNow = new Date();
    dateNow.setMinutes(dateNow.getMinutes() + 2);
    const authRetryCount = this.cookieService.get(CONSTANTS.AUTH_RETRY_COUNT);
    if(authRetryCount){
      let currentAuthRetryCount = parseInt(authRetryCount);
      if(currentAuthRetryCount <= CONSTANTS.MAX_AUTH_RETRY_COUNT){
        this.cookieService.set( CONSTANTS.AUTH_RETRY_COUNT, (currentAuthRetryCount+1) +'' , dateNow);
        this.authService.redirectToLogin();
        return EMPTY;
      } else {
        this.cookieService.delete(CONSTANTS.AUTH_RETRY_COUNT);
        this.windowRef.nativeWindow.location.assign('/dashboard/404');
        return EMPTY;
      }
    } else {
      this.cookieService.set( CONSTANTS.AUTH_RETRY_COUNT, '1' , dateNow);
      this.authService.redirectToLogin();
      return EMPTY;
    }
  }
}