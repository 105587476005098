import { OnInit, EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { UpdateSubmissionsState } from '../../actions/submissions.actions';
import * as SUBMISSION_CONFIG from '../../../config/submission.config';
import { SubmissionViewManagementService } from '../services/submission-view-management.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(store, renderer, submissionViewManagement) {
        var _this = this;
        this.store = store;
        this.renderer = renderer;
        this.submissionViewManagement = submissionViewManagement;
        this.showActions = false;
        this.showNotifications = false;
        this.isNotificationClicked = {};
        this.isInit = true;
        this.logout = new EventEmitter();
        this.renderer.listen('window', 'click', function (e) {
            if (_this.actions && !(_this.actions.nativeElement).contains(e.target)) {
                _this.showActions = false;
            }
            if (_this.notifications && !(_this.notifications.nativeElement).contains(e.target)) {
                _this.showNotifications = false;
            }
        });
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store
            .select(function (state) { return state.submissions; })
            .subscribe(function (submissionsState) {
            _this.submissionsState = submissionsState;
            if (_this.submissionsState.submissions && _this.submissionsState.submissions.length > 0) {
                _this.activeSubmissions = _this.submissionsState.submissions
                    .filter(function (submission) { return submission.status !== "DELETED"; });
                _this.notifiableSubmissions = _this.activeSubmissions
                    .filter(function (submission) { return _this.isActionRequired(submission); });
                if (_this.isInit) {
                    _this.isInit = false;
                    _this.notificationCount = _this.notifiableSubmissions.length;
                    _this.notifiableSubmissions.forEach(function (submission) {
                        _this.isNotificationClicked[submission.submissionId] = false;
                    });
                }
            }
        });
    };
    Object.defineProperty(HeaderComponent.prototype, "state", {
        get: function () {
            return this.submissionsState;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.handleLogout = function () {
        this.logout.emit();
    };
    HeaderComponent.prototype.showActionsMenu = function () {
        this.showActions = !this.showActions;
    };
    HeaderComponent.prototype.showNotificationsMenu = function () {
        if (this.notifiableSubmissions.length > 0) {
            this.showNotifications = !this.showNotifications;
        }
    };
    HeaderComponent.prototype.isActionRequired = function (submission) {
        if (SUBMISSION_CONFIG.actionStatuses.includes(submission.status)) {
            return true;
        }
    };
    HeaderComponent.prototype.scrollToSubmission = function (submissionId) {
        var _this = this;
        var indexOfSelectedSubFromAllSubs = this.activeSubmissions.findIndex(function (element) { return element.submissionId === submissionId; });
        this.isNotificationClicked[submissionId] = true;
        this.notificationCount = Object.keys(this.isNotificationClicked).filter(function (key) { return !_this.isNotificationClicked[key]; }).length;
        this.submissionViewManagement.scrollToSelectedSubmissionCard(indexOfSelectedSubFromAllSubs);
        this.expandSelectedSubmissionCard(indexOfSelectedSubFromAllSubs);
    };
    HeaderComponent.prototype.expandSelectedSubmissionCard = function (indexOfSelectedSubFromAllSubs) {
        var _this = this;
        setTimeout(function () {
            var selectedIndex = _this.activeSubmissions.findIndex(function (element) { return element.isExpanded; });
            if (selectedIndex !== -1) {
                _this.activeSubmissions[selectedIndex].isExpanded = false;
            }
            _this.activeSubmissions[indexOfSelectedSubFromAllSubs].isExpanded = true;
            _this.showNotifications = false;
            _this.store.dispatch(new UpdateSubmissionsState(_this.activeSubmissions));
        }, 100);
    };
    HeaderComponent.prototype.extractStatusFromMap = function (status) {
        return SUBMISSION_CONFIG.statusMap[status];
    };
    HeaderComponent.prototype.getDisplayName = function (details) {
        var displayName = '';
        if (details) {
            if (details.firstName) {
                displayName = details.firstName;
            }
            else if (details.displayName) {
                displayName = details.displayName;
            }
            else if (details.lastName) {
                displayName = details.lastName;
            }
            else if (details.email) {
                displayName = details.email;
            }
        }
        return displayName;
    };
    HeaderComponent.prototype.onError = function (event, fallbackImgPath) {
        event.target.src = fallbackImgPath;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
