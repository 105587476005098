import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import CONSTANTS from '../../../config/constants';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { WindowRefService } from './window.ref.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i3 from "./window.ref.service";
var AuthService = /** @class */ (function () {
    function AuthService(http, cookieService, windowRef) {
        this.http = http;
        this.cookieService = cookieService;
        this.windowRef = windowRef;
    }
    AuthService.prototype.redirectToLogin = function () {
        var windowRef = this.windowRef.nativeWindow;
        var _a = windowRef.location, protocol = _a.protocol, hostname = _a.hostname, port = _a.port, pathname = _a.pathname;
        var origin = protocol + '//' + hostname + (port ? ':' + port : '');
        var newURL = environment.USER_AUTH_HOST + environment.AUTHORIZE_USER_ENDPOINT
            + '?redirectURI=' + encodeURIComponent(origin + pathname);
        windowRef.open(newURL, '_self');
    };
    AuthService.prototype.saveAuthTokens = function (tokenObj) {
        this.cookieService.set(CONSTANTS.USER_MANAGEMENT_ACCESS_TOKEN_COOKIE, tokenObj.accessToken, this.getExpiryTime(CONSTANTS.USER_MANAGEMENT_ACCESS_TOKEN_COOKIE_EXPIRY), '/');
        this.cookieService.set(CONSTANTS.USER_MANAGEMENT_ID_TOKEN_COOKIE, tokenObj.idToken, this.getExpiryTime(CONSTANTS.USER_MANAGEMENT_ID_TOKEN_COOKIE_EXPIRY), '/');
        this.cookieService.set(CONSTANTS.USER_MANAGEMENT_CODE_COOKIE, tokenObj.code, this.getExpiryTime(CONSTANTS.USER_MANAGEMENT_CODE_COOKIE_EXPIRY), '/');
        this.cookieService.set(CONSTANTS.USER_MANAGEMENT_REFRESH_TOKEN_COOKIE, tokenObj.refreshToken, this.getExpiryTime(CONSTANTS.USER_MANAGEMENT_REFRESH_TOKEN_COOKIE_EXPIRY), '/');
    };
    AuthService.prototype.getExpiryTime = function (tokenExpiry) {
        var expiryTime = new Date(new Date().getTime() + tokenExpiry * 60000);
        return expiryTime;
    };
    AuthService.prototype.clearAuthToken = function () {
        this.cookieService.delete(CONSTANTS.USER_MANAGEMENT_ACCESS_TOKEN_COOKIE, '/');
        this.cookieService.delete(CONSTANTS.USER_MANAGEMENT_ID_TOKEN_COOKIE, '/');
        this.cookieService.delete(CONSTANTS.USER_MANAGEMENT_CODE_COOKIE, '/');
        this.cookieService.delete(CONSTANTS.USER_MANAGEMENT_REFRESH_TOKEN_COOKIE, '/');
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        var idtoken = this.cookieService.get(CONSTANTS.USER_MANAGEMENT_ID_TOKEN_COOKIE);
        var url = environment.AUTH_V2_LOGOUT_HOST + environment.LOGOUT;
        var body = {
            idtoken: idtoken
        };
        var reqOptions = { withCredentials: true };
        this.http.post(url, body, reqOptions)
            .subscribe(function () { _this.onLogoutSuccess(); }, this.onLogoutError);
    };
    AuthService.prototype.onLogoutSuccess = function () {
        this.clearAuthToken();
        this.redirectToLogin();
    };
    AuthService.prototype.onLogoutError = function () {
        this.windowRef.nativeWindow.location.assign('/dashboard/404');
    };
    AuthService.prototype.getUserDetails = function () {
        var token = this.cookieService.get(CONSTANTS.USER_MANAGEMENT_ACCESS_TOKEN_COOKIE);
        var url = environment.USER_AUTH_HOST + environment.AUTH_SELF_DATA_ENDPOINT + '?accessToken=' + token;
        return this.http
            .get(url)
            .pipe(map(function (response) { return response.data; }));
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.CookieService), i0.inject(i3.WindowRefService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
