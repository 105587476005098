/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/ngx-skeleton-loader/ngx-skeleton-loader.ngfactory";
import * as i4 from "ngx-skeleton-loader";
import * as i5 from "./header.component";
import * as i6 from "@ngxs/store";
import * as i7 from "../services/submission-view-management.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationCount; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["class", "text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToSubmission(_v.context.$implicit.submissionId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [["alt", "exclamation icon"], ["class", "info-icon"], ["src", "assets/img/exclamation.webp"]], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event, "assets/img/exclamation.png") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "span", [["class", "notification-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " on submission "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [["class", "submission-id"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" to "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "info-icon"; var currVal_1 = (_co.isNotificationClicked[_v.context.$implicit.submissionId] ? "grayscale" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.extractStatusFromMap(_v.context.$implicit.status); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.submissionId; _ck(_v, 6, 0, currVal_3); var currVal_4 = (_v.context.$implicit.journal ? _v.context.$implicit.journal.name : null); _ck(_v, 9, 0, currVal_4); }); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "notification-list notification-tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "notification-header-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Actions"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifiableSubmissions; _ck(_v, 5, 0, currVal_0); }, null); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["notifications", 1]], null, 7, "div", [["class", "notifications"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "bell-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showNotificationsMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "bell icon"], ["class", "logo-notification"], ["height", "22px"], ["src", "assets/img/notification.svg"], ["width", "60px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bell-icon"; var currVal_1 = ((_co.notifiableSubmissions && (_co.notifiableSubmissions.length > 0)) ? "cursor-pointer" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.notificationCount; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.showNotifications; _ck(_v, 7, 0, currVal_3); }, null); }
function View_HeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngx-skeleton-loader", [["appearance", "circle"]], null, null, null, i3.View_NgxSkeletonLoaderComponent_0, i3.RenderType_NgxSkeletonLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.NgxSkeletonLoaderComponent, [], { appearance: [0, "appearance"], theme: [1, "theme"] }, null), i1.ɵpod(2, { width: 0, height: 1, "margin-top": 2, "animation-duration": 3 })], function (_ck, _v) { var currVal_0 = "circle"; var currVal_1 = _ck(_v, 2, 0, "30px", "30px", "20px", "2.5s"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_HeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngx-skeleton-loader", [], null, null, null, i3.View_NgxSkeletonLoaderComponent_0, i3.RenderType_NgxSkeletonLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.NgxSkeletonLoaderComponent, [], { theme: [0, "theme"] }, null), i1.ɵpod(2, { width: 0, height: 1, "border-radius": 2, "margin-top": 3, "animation-duration": 4 })], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "150px", "30px", "0", "25px", "2.5s"); _ck(_v, 1, 0, currVal_0); }, null); }
function View_HeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["actions", 1]], null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.getDisplayName(_co.userDetails); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.getDisplayName(_co.userDetails); _ck(_v, 4, 0, currVal_1); }, null); }
function View_HeaderComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "ul", [["class", "action-list toolTip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "text"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "user icon"], ["class", "user-icon"], ["src", "assets/img/user.webp"]], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event, "assets/img/user.png") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "action-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage Account"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "a", [["class", "text gray-out"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "article icon"], ["class", "user-icon"], ["src", "assets/img/article.webp"]], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event, "assets/img/article.png") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "action-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Articles"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["class", "text"], ["href", "https://authorservices.taylorandfrancis.com/contact/"], ["rel", "noopener noreferrer"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["alt", "info icon"], ["class", "info-icon"], ["src", "assets/img/info.webp"]], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event, "assets/img/info.png") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "action-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Help"])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "a", [["class", "text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "img", [["alt", "logout icon"], ["class", "user-icon"], ["src", "assets/img/logout.webp"]], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event, "assets/img/logout.png") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["class", "action-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerProps.accountUrl; _ck(_v, 2, 0, currVal_0); }); }
function View_HeaderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["actions", 1]], null, 7, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showActionsMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "user icon"], ["class", "logo-small"], ["height", "20px"], ["src", "assets/img/user.webp"], ["width", "39px"]], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event, "assets/img/user.png") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "user-name"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Hi, ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-angle-down"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_9)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showActions; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.getDisplayName(_co.userDetails), ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.getDisplayName(_co.userDetails); _ck(_v, 4, 0, currVal_1); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { actions: 0 }), i1.ɵqud(671088640, 2, { notifications: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "tandf logo"], ["class", "logo-image"], ["height", "100%"], ["width", "100%"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["style", "display:inline-flex;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_8)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.userDetails; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.userDetails; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.userDetails; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerProps.href; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.headerProps.logo; _ck(_v, 5, 0, currVal_1); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.HeaderComponent, [i6.Store, i1.Renderer2, i7.SubmissionViewManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i5.HeaderComponent, View_HeaderComponent_Host_0, { headerProps: "headerProps", userDetails: "userDetails" }, { logout: "logout" }, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
