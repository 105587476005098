import { Submission } from '../models/submission';

export class FetchSubmissions {
  static readonly type = '[Dashboard] Fetch Submissions';
}

export class UpdateSubmissionsState {
  static readonly type = '[Dashboard] Update Submissions State';
  constructor(public submissions: Submission[]) {}
}

export class FetchSubmissionsSuccess {
  static readonly type = '[Dashboard] Fetch Submissions Success';
  constructor(public submissions: Submission[]) {}
}

export class FetchSubmissionsFailed {
  static readonly type = '[Dashboard] Fetch Submissions Failed';
  constructor(public error: any) {}
}

export class DeleteSubmission {
  static readonly type = '[Submission List] Delete Submission';
  constructor(public submissionId: string) {}
}

export class WithdrawnSubmissionSuccess {
  static readonly type = '[Submission List] Withdrawn Submission Success';
  constructor(public submission: Submission) {}
}

export class WithdrawnSubmissionFailed {
  static readonly type = '[Submission List] Withdrawn Submission Failed';
  constructor(public submission: Submission, public error: any) {}
}
export class WithdrawnSubmission {
  static readonly type = '[Submission List] Withdrawn Submission';
  constructor(public submission: Submission) {}
}

export class DeleteSubmissionSuccess {
  static readonly type = '[Submission List] Delete Submission Success';
  constructor(public submissionId: string) {}
}

export class DeleteSubmissionFailed {
  static readonly type = '[Submission List] Delete Submission Failed';
  constructor(public submissionId: string, public error: any) {}
}

export class ClearDeleteSubmissionError {
  static readonly type = '[Submission List] Clear Delete Submission Error';
}
