import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Submission } from '../../models/submission';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable()
export class SubmissionsService {
  constructor(private http: HttpClient, private cookieService: CookieService) { }
  upadtedSubmission;
  getSubmissions(): Observable<Submission[]> {
    const userSubmissions = sessionStorage.getItem('userSubmissions');
    if (userSubmissions) {
      const submissions: Submission[] = JSON.parse(userSubmissions);
      sessionStorage.removeItem('userSubmissions');
      return of(submissions);
    } else {
      const url = environment.MRO_HOST + environment.MRO_METADATA_ENDPOINT;
      return this.http.get<Submission[]>(url);
    }
  }

  downloadPdf(submissionId, fileName): any {
    const encodedFileName = encodeURIComponent(fileName);
    let urlParam = submissionId + '?fileName=' + encodedFileName;
    const url = environment.CSS_HOST + environment.CSS_FILE_UPLOAD_ENDPOINT + '/' + urlParam
    return this.http.get(url, { responseType: 'arraybuffer' })
  }

  deleteSubmission(submissionId: string): Observable<any> {
    const url = environment.MMS_HOST + environment.MMS_DELETE_SUBMISSION + submissionId;
    return this.http.delete(url);
  }

  withdrawSubmission(submission: Submission){
    const body={
      submission
    }
    const url = environment.MMS_HOST+environment.MMS_WITHDRAW_SUBMISSION + submission.submissionId+ "?revision="+submission.revision;
    return this.http.put<any>(url, body);
  }
}
