<header>
  <div class="logo">
    <a [href]="headerProps.href" target="_blank">
      <img class="logo-image" [src]="headerProps.logo" alt="tandf logo" height="100%" width="100%" />
    </a>
  </div>
  <div style="display:inline-flex;">

    <div #notifications *ngIf="userDetails" class="notifications">
      <a class="bell-icon" [ngClass]="notifiableSubmissions && notifiableSubmissions.length > 0 ? 'cursor-pointer' : ''" (click)="showNotificationsMenu()">
        <img class="logo-notification" src="assets/img/notification.svg" alt="bell icon" width="60px" height="22px" />
        <span *ngIf="notificationCount" class="badge">{{notificationCount}}</span>
      </a>
      <div class="notification-list notification-tooltip" *ngIf="showNotifications">
        <div class="notification-header-text">Actions</div>
        <ul>
          <li class="text" *ngFor="let submission of notifiableSubmissions"
            (click)="scrollToSubmission(submission.submissionId)">
            <img class="info-icon" [ngClass]="isNotificationClicked[submission.submissionId] ? 'grayscale' : ''"
              src="assets/img/exclamation.webp" alt="exclamation icon"
              (error)="onError($event, 'assets/img/exclamation.png')" />
            <span class="notification-text">{{extractStatusFromMap(submission.status)}} on submission <strong
                class="submission-id">{{submission.submissionId}}</strong> to <strong>{{submission.journal ?
                submission.journal.name : null}}</strong></span>
          </li>
        </ul>
      </div>
    </div>
    <div #actions *ngIf="!userDetails" class="actions">
      <ngx-skeleton-loader *ngIf="!getDisplayName(userDetails)" appearance="circle" [theme]="{ width: '30px', height: '30px','margin-top':'20px','animation-duration': '2.5s' }">
      </ngx-skeleton-loader>
      <ngx-skeleton-loader *ngIf="!getDisplayName(userDetails)"
      [theme]="{ width: '150px',height:'30px', 'border-radius': '0','margin-top':'25px','animation-duration': '2.5s' }"></ngx-skeleton-loader>
     </div>
    <div #actions *ngIf="userDetails" class="actions">
      <a class="text" (click)="showActionsMenu()">
        <img class="logo-small" src="assets/img/user.webp" (error)="onError($event, 'assets/img/user.png')"
          alt="user icon" height="20px" width="39px" />
        <p class="user-name" title="{{ getDisplayName(userDetails) }}">Hi, {{ getDisplayName(userDetails) }}</p>
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </a>
      <ul class="action-list toolTip" *ngIf="showActions">
        <li>
          <a class="text" [href]="headerProps.accountUrl" target="_blank">
            <img class="user-icon" src="assets/img/user.webp" alt="user icon"
              (error)="onError($event, 'assets/img/user.png')" />
            <span class="action-text">Manage Account</span>
          </a>
        </li>
        <li>
          <a class="text gray-out">
            <img class="user-icon" src="assets/img/article.webp" alt="article icon"
              (error)="onError($event, 'assets/img/article.png')" />
            <span class="action-text">My Articles</span>
          </a>
        </li>
        <li>
          <a class="text" href="https://authorservices.taylorandfrancis.com/contact/" target="_blank"
            rel="noopener noreferrer">
            <img class="info-icon" src="assets/img/info.webp" alt="info icon"
              (error)="onError($event, 'assets/img/info.png')" />
            <span class="action-text">Help</span>
          </a>
        </li>
        <li>
          <a class="text" (click)="handleLogout()">
            <img class="user-icon" src="assets/img/logout.webp" alt="logout icon"
              (error)="onError($event, 'assets/img/logout.png')" />
            <span class="action-text">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</header>