import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import CONSTANTS from '../../../config/constants';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/auth.service';
import { SentryErrorHandler } from '../services/sentry.error.handler';
import { WindowRefService } from '../services/window.ref.service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i2 from "../services/auth.service";
import * as i3 from "../services/sentry.error.handler";
import * as i4 from "../services/window.ref.service";
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(cookieService, authService, sentryErrorHandler, windowRef) {
        this.cookieService = cookieService;
        this.authService = authService;
        this.sentryErrorHandler = sentryErrorHandler;
        this.windowRef = windowRef;
    }
    AuthInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        var authorizationToken = 'idtoken ' +
            this.cookieService.get(CONSTANTS.USER_MANAGEMENT_ID_TOKEN_COOKIE);
        var accessToken = this.cookieService.get(CONSTANTS.USER_MANAGEMENT_ACCESS_TOKEN_COOKIE);
        var clone = request.clone({
            setHeaders: this.getHeaders(request.url, accessToken, authorizationToken)
        });
        return next
            .handle(clone)
            .pipe(catchError(function (err) { return _this.handleHttpError(err); }));
    };
    AuthInterceptor.prototype.getHeaders = function (requestUrl, accessToken, authorizationToken) {
        var headers = {
            'USR-MGMT-ACCESS-TOKEN': accessToken,
            Authorization: authorizationToken,
            'Content-Type': 'application/json'
        };
        if (!requestUrl.includes('secure')) {
            headers['Cache-Control'] = 'no-cache';
            headers['Pragma'] = 'no-cache';
        }
        return headers;
    };
    AuthInterceptor.prototype.handleHttpError = function (error) {
        var errorMessage = error.message || 'Unlabled Error';
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401 || error.status === 403) {
                this.handleErrorStatus();
            }
            else {
                this.sentryErrorHandler.sendError(errorMessage, error);
                return throwError(error);
            }
        }
        else {
            this.sentryErrorHandler.sendError(errorMessage, error);
            return throwError(error);
        }
    };
    AuthInterceptor.prototype.handleErrorStatus = function () {
        var dateNow = new Date();
        dateNow.setMinutes(dateNow.getMinutes() + 2);
        var authRetryCount = this.cookieService.get(CONSTANTS.AUTH_RETRY_COUNT);
        if (authRetryCount) {
            var currentAuthRetryCount = parseInt(authRetryCount);
            if (currentAuthRetryCount <= CONSTANTS.MAX_AUTH_RETRY_COUNT) {
                this.cookieService.set(CONSTANTS.AUTH_RETRY_COUNT, (currentAuthRetryCount + 1) + '', dateNow);
                this.authService.redirectToLogin();
                return EMPTY;
            }
            else {
                this.cookieService.delete(CONSTANTS.AUTH_RETRY_COUNT);
                this.windowRef.nativeWindow.location.assign('/dashboard/404');
                return EMPTY;
            }
        }
        else {
            this.cookieService.set(CONSTANTS.AUTH_RETRY_COUNT, '1', dateNow);
            this.authService.redirectToLogin();
            return EMPTY;
        }
    };
    AuthInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function AuthInterceptor_Factory() { return new AuthInterceptor(i0.inject(i1.CookieService), i0.inject(i2.AuthService), i0.inject(i3.SentryErrorHandler), i0.inject(i4.WindowRefService)); }, token: AuthInterceptor, providedIn: "root" });
    return AuthInterceptor;
}());
export { AuthInterceptor };
