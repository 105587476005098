import { ElementRef } from '@angular/core';
import { WindowRefService } from './window.ref.service';
import * as i0 from "@angular/core";
import * as i1 from "./window.ref.service";
var SubmissionViewManagementService = /** @class */ (function () {
    function SubmissionViewManagementService(windowRef) {
        this.windowRef = windowRef;
        this.scrollInProgress = false;
    }
    SubmissionViewManagementService.prototype.setSubmissionListElementRef = function (element) {
        this.submissionListElementRef = element;
    };
    SubmissionViewManagementService.prototype.getSubmissionListElementRef = function () {
        return this.submissionListElementRef;
    };
    SubmissionViewManagementService.prototype.scrollToSelectedSubmissionCard = function (indexOfSelectedSubFromAllSubs) {
        var _this = this;
        this.scrollInProgress = true;
        var el = this.submissionListElementRef.nativeElement.querySelector('#submission-' + indexOfSelectedSubFromAllSubs);
        setTimeout(function () {
            _this.windowRef.nativeWindow.scroll({
                top: el.getBoundingClientRect().top + _this.windowRef.nativeWindow.scrollY - 25,
                behavior: 'smooth'
            });
            _this.scrollInProgress = false;
        }, 50);
    };
    SubmissionViewManagementService.ngInjectableDef = i0.defineInjectable({ factory: function SubmissionViewManagementService_Factory() { return new SubmissionViewManagementService(i0.inject(i1.WindowRefService)); }, token: SubmissionViewManagementService, providedIn: "root" });
    return SubmissionViewManagementService;
}());
export { SubmissionViewManagementService };
