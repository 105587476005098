<div class="banner">
    <a *ngIf='!isMobileView' class="banner-link" [href]="bannerProps.href" target="_blank">
        <img class="banner-image" src="assets/img/OpenAccess_banner.webp"
            (error)="onError($event, 'assets/img/OpenAccess_banner.png')" alt="Open Access publishing banner"
            width="100%" height="100%" />
    </a>
</div>
<div class="banner-mobile">
    <a *ngIf='isMobileView' class="banner-link-mobile" [href]="bannerProps.mobilehref" target="_blank">
        <img class="banner-image-mobile" src="assets/img/openAccess_mobile_banner.webp"
            (error)="onError($event, 'assets/img/openAccess_mobile_banner.png')"
            alt="Open Access publishing banner mobile" width="100%" height="100%" />
    </a>
</div>