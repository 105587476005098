/* jshint esversion: 6 */

export default class CONSTANTS {
  static readonly 'USER_MANAGEMENT_ACCESS_TOKEN_COOKIE' = 'USR_MGMT_ACCESS_TOKEN';
  static readonly 'USER_MANAGEMENT_CODE_COOKIE' = 'USR_MGMT_CODE';
  static readonly 'USER_MANAGEMENT_ID_TOKEN_COOKIE' = 'USR_MGMT_ID_TOKEN';
  static readonly 'USER_MANAGEMENT_REFRESH_TOKEN_COOKIE' = 'USR_MGMT_REFRESH_TOKEN';
  static readonly 'USER_MANAGEMENT_AUTH_TOKEN_COOKIE' = 'USR_MGMT_AUTH_TOKEN';
  static readonly 'AUTH_RETRY_COUNT' = 'AUTH_RETRY_COUNT';
  static readonly 'MAX_AUTH_RETRY_COUNT' = 3;
  static readonly 'USER_MANAGEMENT_ACCESS_TOKEN_COOKIE_EXPIRY' = 60;
  static readonly 'USER_MANAGEMENT_CODE_COOKIE_EXPIRY' = 60;
  static readonly 'USER_MANAGEMENT_ID_TOKEN_COOKIE_EXPIRY' = 60;
  static readonly 'USER_MANAGEMENT_REFRESH_TOKEN_COOKIE_EXPIRY' = 60;

  static readonly journalConfig = {
    name: 'TaylorAndFrancis',
    email: 'rpsupport@tandf.co.uk',
    contactEmail: 'authorqueries@tandf.co.uk',
    website: 'https://www.tandfonline.com/',
    favicon: 'https://static.rp.tandfonline.com/tandf/images/favicon.ico',
    header: {
      logo: 'assets/img/tandf_informa_logo.svg',
      href: 'https://tandfonline.com'
    },
    footer: {
      infoLinks: [
        { name: 'Contact Us', url: 'https://authorservices.taylorandfrancis.com/contact/'},
        { name: 'Accessibility', url: 'https://www.tandfonline.com/accessibility/'}
      ],
      logo: {
        url: 'assets/img/tandf_footer.svg',
        href: 'https://www.tandfonline.com/',
        alt: 'tandf footer logo'
      },
      socialLinks: [
        { url: 'https://twitter.com/tandfnewsroom', name: 'twitter', icon: 'fa-twitter'},
        { url: 'https://www.facebook.com/TaylorandFrancisGroup', name: 'facebook', icon: 'fa-facebook'},
        { url: 'https://www.linkedin.com/company/taylor-&-francis-group', name: 'linkedin', icon: 'fa-linkedin'},
        { url: 'https://www.youtube.com/user/TaylorandFrancis', name: 'youtube', icon: 'fa-youtube'},
        { url: 'https://www.weibo.com/tandfchina', name: 'weibo', icon: 'fa-weibo'}
      ],
      legalLinks: [
        { url: 'https://informa.com/privacy-policy', name: 'Privacy Policy'},
        { url: 'https://rp.tandfonline.com/static/cookie-policy', name: 'Cookies Policy'},
        { url: 'https://rp.tandfonline.com/static/termsandconditions', name: 'Terms & Conditions'}
      ],
      cookiePolicyUrl: 'https://rp.tandfonline.com/static/cookie-policy'
    }
  }
}
