import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import CONSTANTS from '../../../config/constants';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { map } from 'rxjs/operators';
import { WindowRefService } from './window.ref.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor( private http: HttpClient, private cookieService: CookieService, private windowRef: WindowRefService ) { }

  redirectToLogin() {
    const windowRef = this.windowRef.nativeWindow;
    const { protocol, hostname, port, pathname } = windowRef.location;
    const origin = protocol + '//' + hostname + (port ? ':' + port : '');
    const newURL = environment.USER_AUTH_HOST + environment.AUTHORIZE_USER_ENDPOINT
      + '?redirectURI=' + encodeURIComponent(origin + pathname);
    windowRef.open(newURL, '_self');
  }

  saveAuthTokens(tokenObj: any) {
    this.cookieService.set(CONSTANTS.USER_MANAGEMENT_ACCESS_TOKEN_COOKIE, tokenObj.accessToken, this.getExpiryTime(CONSTANTS.USER_MANAGEMENT_ACCESS_TOKEN_COOKIE_EXPIRY), '/');
    this.cookieService.set(CONSTANTS.USER_MANAGEMENT_ID_TOKEN_COOKIE, tokenObj.idToken, this.getExpiryTime(CONSTANTS.USER_MANAGEMENT_ID_TOKEN_COOKIE_EXPIRY), '/');
    this.cookieService.set(CONSTANTS.USER_MANAGEMENT_CODE_COOKIE, tokenObj.code, this.getExpiryTime(CONSTANTS.USER_MANAGEMENT_CODE_COOKIE_EXPIRY), '/');
    this.cookieService.set(CONSTANTS.USER_MANAGEMENT_REFRESH_TOKEN_COOKIE, tokenObj.refreshToken, this.getExpiryTime(CONSTANTS.USER_MANAGEMENT_REFRESH_TOKEN_COOKIE_EXPIRY), '/');
  }

  getExpiryTime(tokenExpiry: any) {
    let expiryTime = new Date(new Date().getTime() + tokenExpiry*60000);
    return expiryTime;
  }

  clearAuthToken() {
    this.cookieService.delete(CONSTANTS.USER_MANAGEMENT_ACCESS_TOKEN_COOKIE, '/');
    this.cookieService.delete(CONSTANTS.USER_MANAGEMENT_ID_TOKEN_COOKIE, '/');
    this.cookieService.delete(CONSTANTS.USER_MANAGEMENT_CODE_COOKIE, '/');
    this.cookieService.delete(CONSTANTS.USER_MANAGEMENT_REFRESH_TOKEN_COOKIE, '/');
  }

  logout() {
    const idtoken = this.cookieService.get(CONSTANTS.USER_MANAGEMENT_ID_TOKEN_COOKIE);
    const url = environment.AUTH_V2_LOGOUT_HOST + environment.LOGOUT;
    const body = {
      idtoken
    };
    const reqOptions = { withCredentials: true };
    this.http.post(url, body, reqOptions)
      .subscribe(() => { this.onLogoutSuccess(); }, this.onLogoutError);
  }

  onLogoutSuccess() {
    this.clearAuthToken();
    this.redirectToLogin();
  }

  onLogoutError() {
    this.windowRef.nativeWindow.location.assign('/dashboard/404');
  }

  getUserDetails(): Observable<User> {
    const token = this.cookieService.get(CONSTANTS.USER_MANAGEMENT_ACCESS_TOKEN_COOKIE);
    const url = environment.USER_AUTH_HOST + environment.AUTH_SELF_DATA_ENDPOINT + '?accessToken=' + token;
    return this.http
      .get<any>(url)
      .pipe(
        map(
          response => <User>response.data
        )
      );
  }
}
