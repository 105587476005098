<div class="app-component">
  <app-header [headerProps]="headerProps" (logout)="onLogout()" [userDetails]="appState?.userDetails"></app-header>
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>
  <div class="banner-footer">
    <app-banner [bannerProps]="bannerProps"></app-banner>
    <app-footer [footerProps]="footerProps"></app-footer>
  </div>
</div>
