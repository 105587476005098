import { User } from '../models/user';

export class FetchUserDetails {
  static readonly type = '[Dashboard] Fetch User Details';
}

export class FetchUserDetailsSuccess {
  static readonly type = '[App] Fetch User Details Success';
  constructor(public userDetails: User) {}
}

export class FetchUserDetailsFailed {
  static readonly type = '[App] Fetch User Details Failed';
  constructor(public error: any) {}
}
