import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CookieNotificationsComponent } from './notifications/cookie-notifications.component';
import { SubmissionsService } from './submissions/submissions.service';
import {BannerComponent} from './banner/banner.component';
import { WindowRefService } from './services/window.ref.service';
import { SubmissionViewManagementService } from './services/submission-view-management.service';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
@NgModule({
  imports: [CommonModule,NgxSkeletonLoaderModule,RouterModule.forRoot([])],
  declarations: [
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    CookieNotificationsComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    CookieNotificationsComponent,
  ],
  providers: [
    SubmissionsService,
    WindowRefService,
    SubmissionViewManagementService
  ]
})
export class CoreModule {}
