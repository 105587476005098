import { Injectable, ElementRef } from '@angular/core';
import { WindowRefService } from './window.ref.service';

@Injectable({
  providedIn: 'root'
})
export class SubmissionViewManagementService {

  public submissionListElementRef: ElementRef;
  public scrollInProgress: boolean = false;

  constructor(
    private windowRef: WindowRefService
  ) { }

  setSubmissionListElementRef(element) {
    this.submissionListElementRef = element;
  }

  getSubmissionListElementRef(): any {
    return this.submissionListElementRef;
  }

  scrollToSelectedSubmissionCard(indexOfSelectedSubFromAllSubs) {
    this.scrollInProgress = true;
    
    const el = this.submissionListElementRef.nativeElement.querySelector('#submission-' + indexOfSelectedSubFromAllSubs);  

    setTimeout(() => {
      this.windowRef.nativeWindow.scroll({
        top: el.getBoundingClientRect().top + this.windowRef.nativeWindow.scrollY - 25,
        behavior: 'smooth'
      });
      this.scrollInProgress = false;
    }, 50);

  }
}
