import { State, Action, StateContext } from '@ngxs/store';
import { Submission } from '../../models/submission';
import { SubmissionsService } from './submissions.service';
import { FetchSubmissions, FetchSubmissionsSuccess,
  FetchSubmissionsFailed, DeleteSubmission, DeleteSubmissionFailed,
  DeleteSubmissionSuccess, ClearDeleteSubmissionError, UpdateSubmissionsState, WithdrawnSubmission, WithdrawnSubmissionSuccess, WithdrawnSubmissionFailed } from '../../actions/submissions.actions';
import { hiddenInvitationStatuses } from 'src/config/submission.config';
import produce from 'immer';


export interface SubmissionsStateModel {
  submissions: Submission[];
  loading: boolean;
  error: any;
  deleteSubmissionLoading: boolean;
  deleteSubmissionError: any;
  showSuccessToastMsg: any;
  showFailureToastMsg: any;
}

@State<SubmissionsStateModel>({
  name: 'submissions',
  defaults: {
    submissions: [],
    loading: false,
    error: null,
    deleteSubmissionLoading: false,
    deleteSubmissionError: null,
    showSuccessToastMsg: false,
    showFailureToastMsg: false
  }
})
export class SubmissionsState {
  constructor(private submissionsService: SubmissionsService) {}

  @Action(FetchSubmissions)
  fetchSubmissions({
    patchState,
    dispatch
  }: StateContext<SubmissionsStateModel>) {
    patchState({ loading: true });

    return this.submissionsService.getSubmissions().subscribe(
      (submissions: Submission[]) => {
        // filtering the submission with status INVITATION_STUB_CREATED
        submissions = submissions.filter((submission =>  !hiddenInvitationStatuses.includes(submission.status)));
        submissions.forEach((submission) => {
          submission.isExpanded = false;
        });

        return dispatch(new FetchSubmissionsSuccess(submissions));
      },
      error => {
        return dispatch(new FetchSubmissionsFailed(error));
      }
    );
  }

  @Action(UpdateSubmissionsState)
  updateSubmissionsState(
    { patchState }: StateContext<SubmissionsStateModel>,
    { submissions }: FetchSubmissionsSuccess
  ) {
    patchState({
      loading: false,
      error: null,
      submissions
    });
  }

  @Action(FetchSubmissionsSuccess)
  fetchSubmissionsSuccess(
    { patchState }: StateContext<SubmissionsStateModel>,
    { submissions }: FetchSubmissionsSuccess
  ) {
    patchState({
      loading: false,
      error: null,
      submissions
    });
  }

  @Action(FetchSubmissionsFailed)
  fetchSubmissionsError(
    { patchState }: StateContext<SubmissionsStateModel>,
    { error }: FetchSubmissionsFailed
  ) {
    patchState({
      loading: false,
      submissions: [],
      error
    });
  }

  @Action(DeleteSubmission)
  deleteSubmission(
    { patchState, dispatch }: StateContext<SubmissionsStateModel>,
    { submissionId }: DeleteSubmission
  ) {
    patchState({ deleteSubmissionLoading: true });

    return this.submissionsService.deleteSubmission(submissionId).subscribe(
      () => {
        return dispatch(new DeleteSubmissionSuccess(submissionId));
      },
      error => {
        return dispatch(new DeleteSubmissionFailed(submissionId, error));
      }
    );
  }

  @Action(DeleteSubmissionSuccess)
  deleteSubmissionSuccess(
    { setState, getState }: StateContext<SubmissionsStateModel>,
    { submissionId }: DeleteSubmissionSuccess
  ) {
    
    const currentState = getState();
    const nextState = produce(currentState, draft => {
      const index = draft.submissions.findIndex(submission => submission.submissionId === submissionId);
      if (index > -1) {
        draft.submissions[index].status = 'DELETED';
      }
      draft.deleteSubmissionLoading = false;
      draft.deleteSubmissionError = null;
      draft.submissions.forEach((submission) => {
        submission.isExpanded = false;
      });
    });
    setState(nextState);
  }

  @Action(DeleteSubmissionFailed)
  deleteSubmissionError(
    { patchState }: StateContext<SubmissionsStateModel>,
    { error, submissionId }: DeleteSubmissionFailed
  ) {
    error.displayMessage = 'Failed to delete submission ' + submissionId;
    patchState({
      deleteSubmissionLoading: false,
      deleteSubmissionError: error
    });
  }

  @Action(ClearDeleteSubmissionError)
  clearDeleteSubmissionError(
    { patchState }: StateContext<SubmissionsStateModel>
  ) {
    patchState({
      deleteSubmissionError: null
    });
  }

  @Action(WithdrawnSubmission)
  updateSubmission(
    { dispatch }: StateContext<SubmissionsStateModel>,
    { submission }: WithdrawnSubmission
  ) {
    return this.submissionsService.withdrawSubmission(submission).subscribe(
      (response) => {
        return dispatch(new WithdrawnSubmissionSuccess(response));
      },
      error => {
        return dispatch(new WithdrawnSubmissionFailed(submission, error));
      }
    );
  }

  @Action(WithdrawnSubmissionSuccess)
  withdrawnSubmissionSuccess(
    { setState, getState, patchState }: StateContext<SubmissionsStateModel>,
    { submission }: WithdrawnSubmissionSuccess)
    {
    const currentState = getState();
    const nextState = produce(currentState, draft => {
      const index = draft.submissions.findIndex(s => s.submissionId === submission.submissionId);
      if (index > -1) {
        draft.submissions[index].status = submission.status;
        draft.submissions[index].auditLogs = submission.auditLogs;
      }
      draft.submissions.forEach((submission) => {
        submission.isExpanded = false;
      });
    });
    patchState({
     showSuccessToastMsg: true
    });
     setState({...nextState});
  }
  @Action(WithdrawnSubmissionFailed)
  withdrawnSubmissionFailed(
    { patchState }: StateContext<SubmissionsStateModel>
  ) {
     patchState({
      showFailureToastMsg: true
     });
      }

}


