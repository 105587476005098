import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { SubmissionsState } from './core/submissions/submissions.state';
import { AppState } from './app.state';
import { SentryErrorHandler } from './core/services/sentry.error.handler';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ToastrModule } from 'ngx-toastr';


const ngModule = {
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    NgxsModule.forRoot([AppState, SubmissionsState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    Title,
    CookieService,
    SentryErrorHandler,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ]
};

if (window.location.hostname.includes('localhost')) {
  ngModule.imports.push(NgxsLoggerPluginModule.forRoot());
}

@NgModule(ngModule)
export class AppModule {}
