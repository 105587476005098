var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { State, Action } from '@ngxs/store';
import { SubmissionsService } from './submissions.service';
import { FetchSubmissions, FetchSubmissionsSuccess, FetchSubmissionsFailed, DeleteSubmission, DeleteSubmissionFailed, DeleteSubmissionSuccess, ClearDeleteSubmissionError, UpdateSubmissionsState, WithdrawnSubmission, WithdrawnSubmissionSuccess, WithdrawnSubmissionFailed } from '../../actions/submissions.actions';
import { hiddenInvitationStatuses } from 'src/config/submission.config';
import produce from 'immer';
var SubmissionsState = /** @class */ (function () {
    function SubmissionsState(submissionsService) {
        this.submissionsService = submissionsService;
    }
    SubmissionsState.prototype.fetchSubmissions = function (_a) {
        var patchState = _a.patchState, dispatch = _a.dispatch;
        patchState({ loading: true });
        return this.submissionsService.getSubmissions().subscribe(function (submissions) {
            // filtering the submission with status INVITATION_STUB_CREATED
            submissions = submissions.filter((function (submission) { return !hiddenInvitationStatuses.includes(submission.status); }));
            submissions.forEach(function (submission) {
                submission.isExpanded = false;
            });
            return dispatch(new FetchSubmissionsSuccess(submissions));
        }, function (error) {
            return dispatch(new FetchSubmissionsFailed(error));
        });
    };
    SubmissionsState.prototype.updateSubmissionsState = function (_a, _b) {
        var patchState = _a.patchState;
        var submissions = _b.submissions;
        patchState({
            loading: false,
            error: null,
            submissions: submissions
        });
    };
    SubmissionsState.prototype.fetchSubmissionsSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var submissions = _b.submissions;
        patchState({
            loading: false,
            error: null,
            submissions: submissions
        });
    };
    SubmissionsState.prototype.fetchSubmissionsError = function (_a, _b) {
        var patchState = _a.patchState;
        var error = _b.error;
        patchState({
            loading: false,
            submissions: [],
            error: error
        });
    };
    SubmissionsState.prototype.deleteSubmission = function (_a, _b) {
        var patchState = _a.patchState, dispatch = _a.dispatch;
        var submissionId = _b.submissionId;
        patchState({ deleteSubmissionLoading: true });
        return this.submissionsService.deleteSubmission(submissionId).subscribe(function () {
            return dispatch(new DeleteSubmissionSuccess(submissionId));
        }, function (error) {
            return dispatch(new DeleteSubmissionFailed(submissionId, error));
        });
    };
    SubmissionsState.prototype.deleteSubmissionSuccess = function (_a, _b) {
        var setState = _a.setState, getState = _a.getState;
        var submissionId = _b.submissionId;
        var currentState = getState();
        var nextState = produce(currentState, function (draft) {
            var index = draft.submissions.findIndex(function (submission) { return submission.submissionId === submissionId; });
            if (index > -1) {
                draft.submissions[index].status = 'DELETED';
            }
            draft.deleteSubmissionLoading = false;
            draft.deleteSubmissionError = null;
            draft.submissions.forEach(function (submission) {
                submission.isExpanded = false;
            });
        });
        setState(nextState);
    };
    SubmissionsState.prototype.deleteSubmissionError = function (_a, _b) {
        var patchState = _a.patchState;
        var error = _b.error, submissionId = _b.submissionId;
        error.displayMessage = 'Failed to delete submission ' + submissionId;
        patchState({
            deleteSubmissionLoading: false,
            deleteSubmissionError: error
        });
    };
    SubmissionsState.prototype.clearDeleteSubmissionError = function (_a) {
        var patchState = _a.patchState;
        patchState({
            deleteSubmissionError: null
        });
    };
    SubmissionsState.prototype.updateSubmission = function (_a, _b) {
        var dispatch = _a.dispatch;
        var submission = _b.submission;
        return this.submissionsService.withdrawSubmission(submission).subscribe(function (response) {
            return dispatch(new WithdrawnSubmissionSuccess(response));
        }, function (error) {
            return dispatch(new WithdrawnSubmissionFailed(submission, error));
        });
    };
    SubmissionsState.prototype.withdrawnSubmissionSuccess = function (_a, _b) {
        var setState = _a.setState, getState = _a.getState, patchState = _a.patchState;
        var submission = _b.submission;
        var currentState = getState();
        var nextState = produce(currentState, function (draft) {
            var index = draft.submissions.findIndex(function (s) { return s.submissionId === submission.submissionId; });
            if (index > -1) {
                draft.submissions[index].status = submission.status;
                draft.submissions[index].auditLogs = submission.auditLogs;
            }
            draft.submissions.forEach(function (submission) {
                submission.isExpanded = false;
            });
        });
        patchState({
            showSuccessToastMsg: true
        });
        setState(__assign({}, nextState));
    };
    SubmissionsState.prototype.withdrawnSubmissionFailed = function (_a) {
        var patchState = _a.patchState;
        patchState({
            showFailureToastMsg: true
        });
    };
    __decorate([
        Action(FetchSubmissions),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "fetchSubmissions", null);
    __decorate([
        Action(UpdateSubmissionsState),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, FetchSubmissionsSuccess]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "updateSubmissionsState", null);
    __decorate([
        Action(FetchSubmissionsSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, FetchSubmissionsSuccess]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "fetchSubmissionsSuccess", null);
    __decorate([
        Action(FetchSubmissionsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, FetchSubmissionsFailed]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "fetchSubmissionsError", null);
    __decorate([
        Action(DeleteSubmission),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, DeleteSubmission]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "deleteSubmission", null);
    __decorate([
        Action(DeleteSubmissionSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, DeleteSubmissionSuccess]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "deleteSubmissionSuccess", null);
    __decorate([
        Action(DeleteSubmissionFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, DeleteSubmissionFailed]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "deleteSubmissionError", null);
    __decorate([
        Action(ClearDeleteSubmissionError),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "clearDeleteSubmissionError", null);
    __decorate([
        Action(WithdrawnSubmission),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, WithdrawnSubmission]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "updateSubmission", null);
    __decorate([
        Action(WithdrawnSubmissionSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, WithdrawnSubmissionSuccess]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "withdrawnSubmissionSuccess", null);
    __decorate([
        Action(WithdrawnSubmissionFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SubmissionsState.prototype, "withdrawnSubmissionFailed", null);
    SubmissionsState = __decorate([
        State({
            name: 'submissions',
            defaults: {
                submissions: [],
                loading: false,
                error: null,
                deleteSubmissionLoading: false,
                deleteSubmissionError: null,
                showSuccessToastMsg: false,
                showFailureToastMsg: false
            }
        }),
        __metadata("design:paramtypes", [SubmissionsService])
    ], SubmissionsState);
    return SubmissionsState;
}());
export { SubmissionsState };
