import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AuthService } from './core/services/auth.service';
import { Store } from '@ngxs/store';
import { FetchUserDetails } from './actions/app.actions';
import { AppStateModel } from './app.state';
import { HeaderProps } from './props/header.props';
import {BannerProps} from './props/banner.props';
import CONSTANTS from '../config/constants';
import { FooterProps } from './props/footer.props';
import { WindowRefService } from './core/services/window.ref.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Author Dashboard';
  appState: AppStateModel;
  headerProps: HeaderProps = <HeaderProps>{};
  footerProps: FooterProps = <FooterProps>{};
  bannerProps: BannerProps = <BannerProps>{};

  public constructor(
    private titleService: Title,
    private authService: AuthService,
    private store: Store,
    public windowRef: WindowRefService
    ) {
    }

    ngOnInit() {
      this.setTitle();
      this.store
      .select(state => state.app)
      .subscribe((appState: AppStateModel) => {
        this.appState = appState;
      });
      this.store.dispatch(new FetchUserDetails());

      this.setHeaderAndFooterProps();
      this.setFavicon();
  }

  setTitle() {
    this.titleService.setTitle(this.title);
  }

  setFavicon() {
    const docRef = this.windowRef.nativeWindow.document;
    const link: any = docRef.querySelector('link[rel*="icon"]') || docRef.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = CONSTANTS.journalConfig.favicon;
    docRef.getElementsByTagName('head')[0].appendChild(link);
  }

  onLogout() {
    this.authService.logout();
  }

  setHeaderAndFooterProps() {
    this.bannerProps.banner = 'https://static.rp.tandfonline.com/tandf/images/OpenAccess_banner.png';
    this.bannerProps.mobileBanner = 'https://static.rp.tandfonline.com/tandf/images/openAccess_mobile_banner.png';
    this.bannerProps.href= 'https://authorservices.taylorandfrancis.com/publishing-open-access/open-select/?utm_source=banner&utm_medium=banner_ad&utm_campaign=JPG15627';
    this.bannerProps.mobilehref = 'https://authorservices.taylorandfrancis.com/publishing-open-access/open-select/?utm_source=banner&utm_medium=banner_ad&utm_campaign=JPH15786'
    this.headerProps.accountUrl = environment.TANDF_ACCOUNT_URL;
    this.headerProps.logo = CONSTANTS.journalConfig.header.logo;
    this.headerProps.href = CONSTANTS.journalConfig.header.href;
    this.footerProps = CONSTANTS.journalConfig.footer;
  }
}
