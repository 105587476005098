export const environment = {
  production: false,
  env: 'uat',
  MRO_HOST: 'https://api-uat.taylorandfrancis.com/v2',
  MRO_METADATA_ENDPOINT: '/metadata/user',

  MMS_HOST: 'https://api-uat.taylorandfrancis.com/v2',
  MMS_DELETE_SUBMISSION: '/submission/',
  MMS_WITHDRAW_SUBMISSION: '/submission/withdraw/',
  ASC_WEB_URL: 'https://uat.taylorfrancis.com/authorcharges',
  DOWNLOAD_INVOICE: 'https://secure-uat.taylorfrancis.com/invoice/',
  JMS_HOST: 'https://api-uat.taylorandfrancis.com/v1/config',
  CSS_HOST: 'https://api-uat.taylorandfrancis.com/v2',
  CSS_FILE_UPLOAD_ENDPOINT: '/files',
  JMS_GET_JOURNAL_BY_CODE_ENDPOINT:'/journal/config/',
  NEW_DSW_WEB_URL:'https://ft-author-dashboard-7s5.pages.dev',
  JMS_JOURNALOWNER_DETAILS_ENDPOINT: '/journal/owner/',
  JMS_GET_JOURNALS_BYCODE_ENDPOINT: '/journal',
  GET_QUOTE: 'https://api.uat.taylorfrancis.net/uat/api/v1/fsd/quote',
  PAYMENT_URL: 'https://secure-uat.taylorandfrancis.com/payment#/?product=tandf&invoice=',
  TANDF_ACCOUNT_URL: 'https://my-uat.taylorandfrancis.com/my-account',
  AUTH_V2_LOGOUT_HOST: 'https://api-uat.taylorfrancis.com/v2/auth/user/auth',
  USER_AUTH_HOST: 'https://api-uat.taylorandfrancis.com/v1/authclient',
  AUTH_SELF_DATA_ENDPOINT: '/user',
  LOGOUT: '/logout',
  ORCID_LOGOUT_ENDPOINT: '/user/auth/logout',
  ORCID_SELF_DATA_ENDPOINT: '/user/self',
  AUTHORIZE_USER_ENDPOINT: '/authorize',
  AUTH_TOKEN_VERIFICATION_ENDPOINT: '/authtokenverification',
  ECOMM_CHECKOUT_PAGE: 'https://secure-uat.taylorfrancis.com/checkout/sp/billing-address'
};
