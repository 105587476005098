import { Injectable, ErrorHandler } from '@angular/core';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    window.newrelic.noticeError(error.originalError || error, { location: location.href});
    throw error;
  }

  sendError(message: string, extra: any) {
    window.newrelic.noticeError(message, { addtionalDetails: extra, location: location.href});
  }
}
