/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./banner.component";
var styles_BannerComponent = [i0.styles];
var RenderType_BannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BannerComponent, data: {} });
export { RenderType_BannerComponent as RenderType_BannerComponent };
function View_BannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "banner-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Open Access publishing banner"], ["class", "banner-image"], ["height", "100%"], ["src", "assets/img/OpenAccess_banner.webp"], ["width", "100%"]], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event, "assets/img/OpenAccess_banner.png") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bannerProps.href; _ck(_v, 0, 0, currVal_0); }); }
function View_BannerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "banner-link-mobile"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Open Access publishing banner mobile"], ["class", "banner-image-mobile"], ["height", "100%"], ["src", "assets/img/openAccess_mobile_banner.webp"], ["width", "100%"]], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event, "assets/img/openAccess_mobile_banner.png") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bannerProps.mobilehref; _ck(_v, 0, 0, currVal_0); }); }
export function View_BannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "banner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannerComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "banner-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannerComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isMobileView; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isMobileView; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banner", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BannerComponent_0, RenderType_BannerComponent)), i1.ɵdid(1, 114688, null, 0, i3.BannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BannerComponentNgFactory = i1.ɵccf("app-banner", i3.BannerComponent, View_BannerComponent_Host_0, { bannerProps: "bannerProps" }, {}, []);
export { BannerComponentNgFactory as BannerComponentNgFactory };
