/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./core/header/header.component.ngfactory";
import * as i3 from "./core/header/header.component";
import * as i4 from "@ngxs/store";
import * as i5 from "./core/services/submission-view-management.service";
import * as i6 from "@angular/router";
import * as i7 from "./core/banner/banner.component.ngfactory";
import * as i8 from "./core/banner/banner.component";
import * as i9 from "./core/footer/footer.component.ngfactory";
import * as i10 from "./core/footer/footer.component";
import * as i11 from "./app.component";
import * as i12 from "@angular/platform-browser";
import * as i13 from "./core/services/auth.service";
import * as i14 from "./core/services/window.ref.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "app-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, [[null, "logout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logout" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.Store, i1.Renderer2, i5.SubmissionViewManagementService], { headerProps: [0, "headerProps"], userDetails: [1, "userDetails"] }, { logout: "logout" }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "page-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "banner-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-banner", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_BannerComponent_0, i7.RenderType_BannerComponent)), i1.ɵdid(8, 114688, null, 0, i8.BannerComponent, [], { bannerProps: [0, "bannerProps"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-footer", [], null, null, null, i9.View_FooterComponent_0, i9.RenderType_FooterComponent)), i1.ɵdid(10, 49152, null, 0, i10.FooterComponent, [], { footerProps: [0, "footerProps"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerProps; var currVal_1 = ((_co.appState == null) ? null : _co.appState.userDetails); _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 5, 0); var currVal_2 = _co.bannerProps; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.footerProps; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i11.AppComponent, [i12.Title, i13.AuthService, i4.Store, i14.WindowRefService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
