var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { State, Action } from '@ngxs/store';
import { FetchUserDetails, FetchUserDetailsSuccess, FetchUserDetailsFailed } from './actions/app.actions';
import { AuthService } from './core/services/auth.service';
var AppState = /** @class */ (function () {
    function AppState(authService) {
        this.authService = authService;
    }
    AppState.prototype.fetch = function (_a) {
        var patchState = _a.patchState, dispatch = _a.dispatch;
        patchState({ loading: true });
        return this.authService.getUserDetails().subscribe(function (userDetails) {
            return dispatch(new FetchUserDetailsSuccess(userDetails));
        }, function (error) {
            return dispatch(new FetchUserDetailsFailed(error));
        });
    };
    AppState.prototype.success = function (_a, _b) {
        var patchState = _a.patchState;
        var userDetails = _b.userDetails;
        patchState({
            loading: false,
            error: null,
            userDetails: userDetails
        });
    };
    AppState.prototype.error = function (_a, _b) {
        var patchState = _a.patchState;
        var error = _b.error;
        patchState({
            loading: false,
            userDetails: null,
            error: error
        });
    };
    __decorate([
        Action(FetchUserDetails),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AppState.prototype, "fetch", null);
    __decorate([
        Action(FetchUserDetailsSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, FetchUserDetailsSuccess]),
        __metadata("design:returntype", void 0)
    ], AppState.prototype, "success", null);
    __decorate([
        Action(FetchUserDetailsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, FetchUserDetailsFailed]),
        __metadata("design:returntype", void 0)
    ], AppState.prototype, "error", null);
    AppState = __decorate([
        State({
            name: 'app',
            defaults: {
                userDetails: null,
                loading: false,
                error: null
            }
        }),
        __metadata("design:paramtypes", [AuthService])
    ], AppState);
    return AppState;
}());
export { AppState };
