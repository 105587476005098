const statusMap = {
  INVITATION_ACCEPTED:"Invitation Accepted",
  INVITATION_STUB_UNASSIGNED:"Invitation Expired",
  INVITATION_EXPIRED: "Invitation Expired",
  SUBMISSION_DEADLINE:"Submission Deadline",
  CREATED: "Submission Created",
  REVISION_CREATED: "Revision Required",
  IN_PROGRESS: "Submission Incomplete",
  REVISION_IN_PROGRESS: "Revision Incomplete",
  COMPLETED: "Manuscript Submitted",
  REVISION_COMPLETED: "Revised Manuscript Submitted",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  DELETED: "Deleted",
  MANUAL_COMPLETED: "Manuscript Sent to Journal",
  WITH_EDITOR: "With Editor",
  UNDER_REVIEW: "Out for Review",
  REVIEWS_COMPLETE: "Reviews Complete",
  DECISION_IN_PROCESS: "Decision Pending",
  WITHDRAWN: "Withdrawn",
  SENT_BACK_TO_AUTHOR: "Submission Returned to Author",
  SUBMISSION_RECEIVED_VIA_INGEST: "With Journal Administrator",
  SUBMISSION_RETURNED_TO_PUBLICATION_VIA_INGEST: "With Journal Administrator",
  REVISION_RECEIVED_VIA_INGEST: "With Journal Administrator",
  SENT_BACK_COMPLETED: "Manuscript Resubmitted",
  SENT_BACK_IN_PROGRESS: "Submission Incomplete",
  RESUBMISSION_RECEIVED_VIA_INGEST: "With Journal Administrator",
  RESUBMISSION_CREATED: "Ready to Revise",
  RESUBMISSION_IN_PROGRESS: "Resubmission Incomplete",
  RESUBMISSION_COMPLETED: "Revised Manuscript Submitted",
  PAYMENT_PENDING: "Payment Pending",
  PAYMENT_PROCESSING: "Payment Processing",
  DECISION_RESCINDED: "Decision Rescinded",
  WITHDRAWN_IN_PROGRESS: "Withdraw In Progress"
};

const showResumeButtonMap = {
  CREATED: true,
  REVISION_CREATED: false,
  IN_PROGRESS: true,
  REVISION_IN_PROGRESS: true,
  COMPLETED: false,
  REVISION_COMPLETED: false,
  ACCEPTED: false,
  REJECTED: false,
  DELETED: false,
  WITH_EDITOR: false,
  UNDER_REVIEW: false,
  REVIEWS_COMPLETE: false,
  DECISION_IN_PROCESS: false,
  WITHDRAWN: false,
  SENT_BACK_TO_AUTHOR: true,
  SUBMISSION_RECEIVED_VIA_INGEST: false,
  SUBMISSION_RETURNED_TO_PUBLICATION_VIA_INGEST: false,
  REVISION_RECEIVED_VIA_INGEST: false,
  SENT_BACK_COMPLETED: false,
  SENT_BACK_IN_PROGRESS: true,
  RESUBMISSION_RECEIVED_VIA_INGEST: false,
  RESUBMISSION_CREATED: false,
  RESUBMISSION_IN_PROGRESS: false,
  RESUBMISSION_COMPLETED: false,
  PAYMENT_PENDING: true,
  DECISION_RESCINDED: false,
  INVITATION_STUB_UNASSIGNED: false,
  INVITATION_ACCEPTED : true,
  WITHDRAWN_IN_PROGRESS:  true
};

const showReviseButtonMap = {
  CREATED: false,
  REVISION_CREATED: true,
  IN_PROGRESS: false,
  REVISION_IN_PROGRESS: false,
  COMPLETED: false,
  REVISION_COMPLETED: false,
  ACCEPTED: false,
  REJECTED: false,
  DELETED: false,
  WITH_EDITOR: false,
  UNDER_REVIEW: false,
  REVIEWS_COMPLETE: false,
  DECISION_IN_PROCESS: false,
  WITHDRAWN: false,
  SENT_BACK_TO_AUTHOR: false,
  SUBMISSION_RECEIVED_VIA_INGEST: false,
  SUBMISSION_RETURNED_TO_PUBLICATION_VIA_INGEST: false,
  REVISION_RECEIVED_VIA_INGEST: false,
  SENT_BACK_COMPLETED: false,
  SENT_BACK_IN_PROGRESS: false,
  RESUBMISSION_RECEIVED_VIA_INGEST: false,
  RESUBMISSION_CREATED: true,
  RESUBMISSION_IN_PROGRESS: true,
  RESUBMISSION_COMPLETED: false,
  PAYMENT_PENDING: false,
  DECISION_RESCINDED: false,
  WITHDRAWN_IN_PROGRESS: false,
};


const showDeleteButtonMap = {
  CREATED: true,
  REVISION_CREATED: false,
  IN_PROGRESS: true,
  REVISION_IN_PROGRESS: false,
  COMPLETED: false,
  REVISION_COMPLETED: false,
  ACCEPTED: false,
  REJECTED: false,
  DELETED: false,
  WITH_EDITOR: false,
  UNDER_REVIEW: false,
  REVIEWS_COMPLETE: false,
  DECISION_IN_PROCESS: false,
  WITHDRAWN: false,
  SENT_BACK_TO_AUTHOR: false,
  SUBMISSION_RECEIVED_VIA_INGEST: false,
  SUBMISSION_RETURNED_TO_PUBLICATION_VIA_INGEST: false,
  REVISION_RECEIVED_VIA_INGEST: false,
  SENT_BACK_COMPLETED: false,
  SENT_BACK_IN_PROGRESS: false,
  RESUBMISSION_RECEIVED_VIA_INGEST: false,
  RESUBMISSION_CREATED: false,
  RESUBMISSION_IN_PROGRESS: false,
  RESUBMISSION_COMPLETED: false,
  PAYMENT_PENDING: true,
  DECISION_RESCINDED: false,
  WITHDRAWN_IN_PROGRESS: false
};
const showPdfButtonMap = {
  CREATED: false,
  REVISION_CREATED: true,
  IN_PROGRESS: false,
  REVISION_IN_PROGRESS: true,
  COMPLETED: true,
  REVISION_COMPLETED: true,
  ACCEPTED: true,
  REJECTED: true,
  DELETED: false,
  WITH_EDITOR: true,
  UNDER_REVIEW: true,
  REVIEWS_COMPLETE: true,
  DECISION_IN_PROCESS: true,
  WITHDRAWN: true,
  SENT_BACK_TO_AUTHOR: true,
  SUBMISSION_RECEIVED_VIA_INGEST: true,
  SUBMISSION_RETURNED_TO_PUBLICATION_VIA_INGEST: true,
  REVISION_RECEIVED_VIA_INGEST: true,
  SENT_BACK_COMPLETED: true,
  SENT_BACK_IN_PROGRESS: true,
  RESUBMISSION_COMPLETED: true,
  RESUBMISSION_CREATED: true,
  RESUBMISSION_IN_PROGRESS: true,
  RESUBMISSION_RECEIVED_VIA_INGEST: true,
  PAYMENT_PENDING: true,
  DECISION_RESCINDED: true
}
const showContactButtonMap = {
  CREATED: false,
  REVISION_CREATED: false,
  IN_PROGRESS: false,
  REVISION_IN_PROGRESS: false,
  COMPLETED: true,
  REVISION_COMPLETED: true,
  ACCEPTED: false,
  REJECTED: false,
  DELETED: false,
  WITH_EDITOR: true,
  UNDER_REVIEW: true,
  REVIEWS_COMPLETE: true,
  DECISION_IN_PROCESS: true,
  WITHDRAWN: true,
  SENT_BACK_TO_AUTHOR: true,
  SUBMISSION_RECEIVED_VIA_INGEST: true,
  SUBMISSION_RETURNED_TO_PUBLICATION_VIA_INGEST: true,
  REVISION_RECEIVED_VIA_INGEST: true,
  SENT_BACK_COMPLETED: true,
  SENT_BACK_IN_PROGRESS: false,
  RESUBMISSION_COMPLETED: true,
  RESUBMISSION_CREATED: false,
  RESUBMISSION_IN_PROGRESS: false,
  RESUBMISSION_RECEIVED_VIA_INGEST: true,
  PAYMENT_PENDING: false,
  DECISION_RESCINDED: true,
  WITHDRAWN_IN_PROGRESS: true
};


const buttonTextMap = {
  CREATED: "Resume",
  REVISION_CREATED: "Revise",
  IN_PROGRESS: "Resume",
  REVISION_IN_PROGRESS: "Resume",
  SENT_BACK_TO_AUTHOR: "Resume",
  SENT_BACK_IN_PROGRESS: "Resume",
  RESUBMISSION_CREATED: "Revise",
  RESUBMISSION_IN_PROGRESS: "Revise"
};

const submissionStageMap = {
  CREATED: "Submission Created",
  IN_PROGRESS: "Submission Incomplete",
  COMPLETED: "Manuscript Submitted",
  SUBMISSION_RECEIVED_VIA_INGEST: "With Journal Administrator",
  SENT_BACK_TO_AUTHOR: "Submission Returned to Author",
  SENT_BACK_IN_PROGRESS: "Submission Incomplete",
  SENT_BACK_COMPLETED: "Manuscript Resubmitted",
  SUBMISSION_RETURNED_TO_PUBLICATION_VIA_INGEST: "With Journal Administrator",
  RESUBMISSION_RECEIVED_VIA_INGEST: "With Journal Administrator"
};


const peerReviewStageMap = {
  REVISION_CREATED: "Revision Required",
  REVISION_IN_PROGRESS: "Revision Incomplete",
  REVISION_COMPLETED: "Revised Manuscript Submitted",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  WITH_EDITOR: "With Editor",
  MANUAL_COMPLETED: "Manuscript Sent to Journal",
  UNDER_REVIEW: "Out for Review",
  REVIEWS_COMPLETE: "Reviews Complete",
  DECISION_IN_PROCESS: "Decision Pending",
  REVISION_RECEIVED_VIA_INGEST: "With Journal Administrator",
  RESUBMISSION_RECEIVED_VIA_INGEST: "With Journal Administrator",
  RESUBMISSION_CREATED: "Ready to Revise",
  RESUBMISSION_IN_PROGRESS: "Resubmission Incomplete",
  RESUBMISSION_COMPLETED: "Revised Manuscript Submitted",
  DECISION_RESCINDED: "Decision Rescinded",
  WITHDRAWN_IN_PROGRESS: "Withdrawn In Progress"

};

const submissionTimelineStageMap = {
  INVITATION:"INVITATION",
  SUBMISSION: "SUBMISSION",
  PEER_REVIEW: "PEER REVIEW",
  PRODUCTION: "PRODUCTION",
  PUBLISHING: "PUBLISHING"
};

const actionStatuses = [
  'REVISION_CREATED',
  'SENT_BACK_TO_AUTHOR',
  'REVISION_IN_PROGRESS',
  'RESUBMISSION_CREATED',
  'RESUBMISSION_IN_PROGRESS'
];

const hiddenInvitationStatuses = [
  'INVITATION_STUB_CREATED',
  'INVITATION_REJECTED',
  'INVITATION_STUB_UNINVITED',
  'INVITATION_NO_RESPONSE',
  'INVITATION_UNAVAILABLE',
  'INVITATION_LATE_RESPONSE',
  'INVITATION_AUTO_DECLINE'
];


export {
  statusMap,
  showResumeButtonMap,
  showReviseButtonMap,
  showDeleteButtonMap,
  showContactButtonMap,
  buttonTextMap,
  submissionStageMap,
  peerReviewStageMap,
  submissionTimelineStageMap,
  actionStatuses,
  showPdfButtonMap,
  hiddenInvitationStatuses
};
