import { Component, OnInit, Input, HostListener } from '@angular/core';
import {BannerProps} from 'src/app/props/banner.props';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
    @Input()
    bannerProps: BannerProps;
    isMobileView = false;

    ngOnInit() {
      this.isMobileView = this.checkMobileView();
    }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobileView = this.checkMobileView();
  }

  checkMobileView() {
     return window.innerWidth <= 641;
  }

  onError(event, fallbackImgPath) {
    event.target.src = fallbackImgPath;
  }
}
